// const { Octokit } = require("@octokit/core");

// const octokit = new Octokit({
//   auth: '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'
// });

// async function getEnterpriseUsage() {
//   try {
//     const response = await octokit.request('GET /repos/LTTS-DTP-APPSTUDIO/AppStudio-MFE_Playground/copilot/usage', {
//       enterprise: 'https://github.com/LTTS-DTP-APPSTUDIO',
//       headers: {
//         'X-GitHub-Api-Version': '2022-11-28'
//       }
//     });

//     console.log(response.data);
//   } catch (error) {
//     console.error(error);
//   }
// }

// getEnterpriseUsage();


const { Octokit } = require("@octokit/core");

const octokit = new Octokit({
  auth: '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'
});

async function getRepoInfo() {
  try {
    const response = await octokit.request('GET /repos/{owner}/{repo}', {
      owner: 'LTTS-DTP-APPSTUDIO',
      repo: 'AppStudio-MFE_Playground'
    });

    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
}

getRepoInfo();
module.exports = getRepoInfo;