// import React, { useState } from 'react';
// import Chatbot from '../src/chatbot';

// function App() {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [message, setMessage] = useState('');
//   const [messages, setMessages] = useState([]);
//   const defaultMessages = [
//     "1.Generate a function to calculate the factorial of a number in Python.",
//     "2.Create a React component that displays a user's profile information.",
//     "3.Write a SQL query to find the top 5 customers with the highest total purchase.",
//     "4.How do I create a Dockerfile for a Node.js application?",
//     "5.Generate a unit test for this function in JavaScript.",
//     "6.Explain how this recursive function works.",
//     "7.Propose a fix for this JavaScript code that's throwing a TypeError.",
//     "8.Create a new Jupyter Notebook with a basic data analysis using pandas.",
//     "9.Find a Python code snippet to make a POST request with the requests library.",
//     "10.How do I create a new branch in Git from the terminal?",
//     "11.Generate query parameters for a workspace search in VS Code.",
//     "12.How do I develop a new extension for VS Code?",
//     "13.Scaffold a new Express.js application.",
//     "14.How do I run this Python script in the terminal?",
//     "15.Ask a question about the files in my current workspace."
//   ];

//   const handleSendMessage = () => {
//     if (message) {
//       setMessages([...messages, message]);
//       setMessage('');
//     }
//   };

//   const filteredMessages = defaultMessages.filter(msg =>
//     msg.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="app">
//       <h1>Copilot Solution ToolKit </h1>
//       <div className="search-bar">
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Search Prompts..."
//         />
//       </div>
//       <div className="message-input">
//         <input
//           type="text"
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//           placeholder="Type your prompts here..."
//         />
//         <button onClick={handleSendMessage}>Type Prompts</button>
//       </div>
//       <div className="content">
//         <h2>Sent Prompts:</h2>
//         {messages.length > 0 ? (
//           messages.map((msg, index) => <p key={index}>{msg}</p>)
//         ) : (
//           <p>No prompts sent yet.</p>
//         )}
//         <h2>Default Prompts:</h2>
//         {filteredMessages.length > 0 ? (
//           filteredMessages.map((msg, index) => <p key={index}>{msg}</p>)
//         ) : (
//           <p>No matching messages found.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default App;


import React, { useState } from 'react';
import Chatbot from '../src/chatbot';
// const getRepoInfo = require('./githubApi.js');

const getRepoInfo = require('./githubApi.js');

// Use the function
getRepoInfo().then(data => {
  // Do something with the data
  console.log(data);
});

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const defaultMessages = [
    // 'Hello, how are you?',
    // 'React is awesome!',
    // 'This is a sample message.',
    // 'Keep coding and stay positive!',
    // 'Learning React is fun!',
    // // Add more messages for testing
    // 'Message 1', 'Message 2', 'Message 3', 'Message 4', 'Message 5',
    // 'Message 6', 'Message 7', 'Message 8', 'Message 9', 'Message 10',
    // 'Message 11', 'Message 12', 'Message 13', 'Message 14', 'Message 15',
  ];

  const handleSendMessage = () => {
    if (message) {
      setMessages([...messages, message]);
      setMessage('');
    }
  };

  const filteredMessages = defaultMessages.filter(msg =>
    msg.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="app">
      <h1>Simple React App</h1>
      <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search messages..."
        />
      </div>
      <div className="message-input">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        />
        <button onClick={handleSendMessage}>Type a Message</button>
      </div>
      <div className="content">
        <h2>Sent Messages:</h2>
        {messages.length > 0 ? (
          messages.map((msg, index) => <p key={index}>{msg}</p>)
        ) : (
          <p>No messages sent yet.</p>
        )}
        <h2>Default Messages:</h2>
        <div className="default-messages">
          {filteredMessages.length > 0 ? (
            filteredMessages.map((msg, index) => <p key={index}>{msg}</p>)
          ) : (
            <p>No matching messages found.</p>
          )}
        </div>
      </div>
      <Chatbot /> {/* Add the Chatbot component here */}
    </div>
  );
}

export default App;