import React, { useState } from 'react';

function Chatbot() {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);

  const handleSend = () => {
    if (userInput) {
      // Simulate a bot response
      const botResponse = `Bot: You said "${userInput}"`;
      setChatHistory([...chatHistory, `You: ${userInput}`, botResponse]);
      setUserInput('');
    }
  };

  return (
    <div className="chatbot">
      <h2>Chatbot</h2>
      <div className="chat-history">
        {chatHistory.length > 0 ? (
          chatHistory.map((msg, index) => <p key={index}>{msg}</p>)
        ) : (
          <p>No chat history yet.</p>
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
}

export default Chatbot;
